import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import util from 'util';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';
import { generatePath } from 'react-router';
import Modal from 'react-modal';
import Control from '../Control/Control';
import Common from '../Common/common.js';
import ArrowOn from '../Images/arrow_on.png';
import ArrowOff from '../Images/arrow_off.png';

const EntryType = (props) => {
  const { user } = props;
  const history = useHistory();
  const { addToast } = useToasts();
  const [entryData, setEntryData] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const [state, setState] = useState({});
  const [settings, setSettings] = useState(null);

  const modalConfirmStyle = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: "rgba(0,0,0,0.5)"
    },
    content: {
      position: "absolute",
      left: '37.5%',
      right: '37.5%',
      top: '20%',
      bottom: 'auto',
      borderRadius: "0rem",
      padding: "0px",
      height: "60%",
      maxHeight: "60%",
      width: "25%",
      overflowX: "hidden",
      overflowY: "scroll",
    }
  };

  const refreshEntry = useCallback(async () => {
    const params = {
      "operator": "and",
      "where": [{ "shop_id": props.match.params.shopId }]
    }
    const result = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/entry_type/search/`, params)).data;
    result.sort((a, b) => a.entry_type < b.entry_type ? -1 : 1);
    setEntryData(result);
  },[props.match.params.shopId]);


  const editButtonClick = async (entry_type) => {
    try {
      history.push(`${generatePath(`${props.match.path}entrytype/edit/${entry_type}`, { shopId: props.match.params.shopId })}`);
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    }
  }

  const registerClick = () => {
    history.push(`${generatePath(`${props.match.path}entrytype/edit/`, { shopId: props.match.params.shopId })}`);
  }

  const previewClick = async () => {
    const reg_params = {
      "operator": "and",
      "where": [
        { "shop_id": props.match.params.shopId },
      ]
    }
    const settings = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/setting/search/`, reg_params)).data;
    setSettings(settings);
    const entry_type = ( await axios.post(`${process.env.REACT_APP_BACKEND_URL}/entry_type/search/`, reg_params)).data;
    entry_type.sort((a, b) => a.entry_type < b.entry_type ? -1 : 1);
    try{
      if(entry_type.length !== entryData.length || entry_type.upd_date !== entryData.upd_date){
        throw new Error('他のユーザーにより更新されています。ページの再読み込みをしてください。');
      } 
      entry_type.forEach((data,idx) => {
        if(data.m_entry_data[0].upd_date !== entryData[idx].m_entry_data[0].upd_date){
          throw new Error('他のユーザーにより更新されています。ページの再読み込みをしてください。');
        }
      })
    }catch(err){
      window.alert(err);
      return;
    }
    entry_type.map((data, idx) => (
      data.m_entry_data.sort((a, b) => a.entry_seq - b.entry_seq)
    ));
    var temp = {...state};
    temp['entry_type'] = entryData;
      entryData.map(data => (
        data.m_entry_data.map((entry_data) => (
          temp[entry_data.param_name] = entry_data.default_value
        ))
      ));
    setState(temp);
    setConfirm(true);
  }

  const checkDataInvalid = () => {
    let ret = false;
    let ret1 = false; 
    if (!state || !state.entry_type) return true;
    state.entry_type.forEach((data, idx) => {
      let check = true; //チェックボックスのチェックをする変数
      data.m_entry_data.forEach((entryData, entryDataIdx) => {
        if (entryData.input_type !== 'check') { //チェックボックスではない時
          check = false; 
          if (data.required && (state[entryData.param_name] === null || state[entryData.param_name] === "")) {
            ret = true;
          } else if (entryData.input_type === 'date' && !Common.checkDate(state[entryData.param_name])) {
            ret = true;
          }
        }
        else {
          if (entryData.input_type === 'check' && !data.required) { //チェックボックスであるが必須ではない時
            check = false;
          }
          if (entryData.input_type === 'check' && data.required && state[entryData.param_name]) { //チェックボックスであり必須で値がある時
            check = false;
          }
        }
      });
      if (check === true) { //子のテーブルの中でcheckの値がfalseにならなければret1をtrueにする
        ret1 = true;
      }
    });
    if (ret === true || ret1 === true) { //retかret1がtrueであればtrueを返す
      return true;
    }
    else {
      return false;
    }
  }

  const updateButton = (data) => {
    // console.log(edit_Flag);
    return (
      <button type="button"
        className={`btn btn-primary text-nowrap`}
        onClick={() => editButtonClick(data.entry_type)}>
        編集
      </button>
    )
  }

  useEffect(() => {
    async function fetchData() {
      await refreshEntry();
    }
    return fetchData();
  }, [refreshEntry]);

  return (
    <div className="container">
      <div className="row d-none"><div className="col-12">{util.inspect(user)}</div></div>
      {entryData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}

      {entryData !== null && (
        <div className="row mb-3 p-0">
          <button className="btn btn-dark mb-3" onClick={previewClick}>プレビュー表示</button>
          <div className="col-12 p-0">
            <table className="table table-bordered table-striped">
              <thead className={`table-info`}>
                <tr>
                  <td className="text-center">
                    入力区分
                  </td>
                  <td className="text-center">
                    入力区分名
                  </td>
                  <td className="text-center ">
                    短縮名
                  </td>
                  <td className="text-center" width="10%">
                    アプリ画面
                  </td>
                  <td className="text-center" width="15%">
                    整理券発行画面
                  </td>
                  <td className="text-center">
                    備考
                  </td>
                  <td className="text-center" width="10%">
                    編集
                  </td>
                </tr>
              </thead>
              <tbody>
                {entryData.map((data, idx) => (
                  <tr key={idx}>
                    {/* 入力区分 */}
                    <td className="text-left align-middle">
                      {data.entry_type}
                    </td>
                    {/* 入力区分名 */}
                    <td className="text-left align-middle ">
                      {data.entry_type_name || ""}
                    </td>
                    {/* 短縮名 */}
                    <td className="text-left align-middle">
                      {data.entry_type_short_name || ""}
                    </td>
                    {/* 必須区分（アプリ画面） */}
                    <td className="text-center align-middle">
                      {data.required === 1 ? "必須" : "任意"}
                    </td>
                    {/* 必須区分（整理券発行画面） */}
                    <td className="text-center align-middle">
                      {data.required_disp === 1 ? "必須" : "任意"}
                    </td>
                    {/* 備考 */}
                    <td className="text-center align-middle">
                      {data.remarks || ""}
                    </td>
                    {/* 編集ボタン */}
                    <td className="text-center align-middle">
                      {updateButton(data)}
                    </td>
                  </tr>
                ))
                }
              </tbody>
            </table>
          </div>
          <button className="btn btn-dark mb-5" onClick={registerClick}>新規登録</button>

          <Modal isOpen={confirm} style={modalConfirmStyle}>
            <header className="bg-light pr-h">
              <div className="bg-light p-2">
                <b>プレビュー</b>
              </div>
              <div className="row mx-0">
                <div className="col section section-top">
                  <span>順番待ち</span>
                </div>
              </div>
            </header>
            <div className={`bg-lightwhite pr-s`}>
              <div className="row mx-0 my-3 px-3-env">
                <div className="col">
                  <div className="row">
                    <div className="col text-center">
                      <span>{Common.getSettingValue(settings, 'LABEL_WAIT_COUNT')}</span>
                    </div>
                  </div>
                  <div className="row p-1">
                    <div className="col text-center ml-2 mr-1">
                      <div className={`row border border-info bg-info`}>
                        <div className="col bg-white px-0 py-1">
                          <h5 className="mb-0 d-inline font-weight-bold">{0}</h5>
                          <span className="d-inline small">{Common.getSettingValue(settings, 'LABEL_WAIT_COUNT_UNIT')}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mx-0">
                <div className="col px-0">
                  {state && state.entry_type && state.entry_type.map((data, idx) => (
                    <div key={idx}>
                      <div className="row mx-0">
                        <div className="col section-dark py-0 line-height-2-2">
                          <span className="mb-0 d-inline-block font-weight-bold align-middle pr-1 font-h4">
                            {//丸数字１～50、51以降は51.と表記
                            idx < 20 ? String.fromCodePoint(9312 + idx) :
                            idx < 35 ? String.fromCodePoint(12881 - 20 + idx) :
                            idx < 50 ? String.fromCodePoint(12977 - 35 + idx) :
                            1 + idx + "."
                          }
                          </span>
                          <span className="d-inline-block align-middle"> {data.entry_type_name}</span>
                        </div>
                      </div>
                      <div className="row mx-0 bg-white px-3-env pt-3 pb-4">
                        {data.m_entry_data !== null && data.m_entry_data.map((entryData, entryDataIdx) => (
                          <Control target={"sp"} setState={(name, value) => { setState({ ...state, [name]: value }); }} m_entry_data={data.m_entry_data} entryData={entryData} entryDataIdx={entryDataIdx} value={state[entryData.param_name]} {...props} key={entryDataIdx} />
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <footer className="bg-light pr-f">
              <button
                disabled={checkDataInvalid()}
                className={`btn-lg btn-submit w-100 py-3 p-env-bottom`}
                onClick={()=> window.alert('プレビューなので申込みをすることはできません。')}>
                申込む <img className="arrow mb-0" src={checkDataInvalid() === true ? ArrowOff : ArrowOn} alt="" />
              </button>
              <div className="row my-2 mx-0">
                <div className="col">
                  <button
                    className={`btn btn-blue-enable w-100 py-1`}
                    onClick={() => { setConfirm(false) }}>
                    <h4 className="font-weight-bold mb-0">プレビューを閉じる</h4>
                  </button>
                </div>
              </div>
            </footer>
          </Modal>
        </div>
      )}

    </div>
  )
}

export default EntryType
