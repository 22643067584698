import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import constClass from '../../Constants/Constants';
import { useToasts } from 'react-toast-notifications';
import Ticket from './Ticket';
import { useReactToPrint } from 'react-to-print';
import Modal from 'react-modal';
import ArrowOn from '../Images/arrow_blue.png';
import ArrowOff from '../Images/arrow_off.png';
import Control from '../Control/Control';
import Common from '../Common/common.js';

const DisplayOrder = (props) => {
  const [disabled, setDisabled] = useState(false);
  // const [classId, setClassId] = useState(null);
  // const [message, setMessage] = useState(null);
  const [order, setOrder] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const { addToast } = useToasts();
  const componentRef = useRef();
  
  const [state, setState] = useState({});

  const modalConfirmStyle = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: "rgba(0,0,0,0.5)"
    },
    content: {
      position: "absolute",
      left: '20%',
      right: '20%',
      top: '20%',
      bottom: 'auto',
      borderRadius: "0rem",
      padding: "0px",
      height: "auto",
      maxHeight: "60%",
      width: "60%",
      overflowX: "hidden",
      overflowY: "scroll",
    }
  };

  // const submitClass = (pClassId) => {
  //   setClassId(pClassId);
  //   setMessage(null);
  // }

  const printTicket = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `@page {
      size: 60mm 160mm;
      margin: 0mm;
    }`,
  });

  const checkDataInvalid = () => {
    let ret = false;
    let ret1 = false; 
    if (!state || !state.entry_type) return true;
    state.entry_type.forEach((data, idx) => {
      let check = true; //チェックボックスのチェックをする変数
      data.m_entry_data.forEach((entryData, entryDataIdx) => {
        if (entryData.input_type !== 'check') { //チェックボックスではない時
          check = false; 
          if (data.required && (state[entryData.param_name] === null || state[entryData.param_name] === "")) {
            ret = true;
          } else if (entryData.input_type === 'date' && !Common.checkDate(state[entryData.param_name])) {
            ret = true;
          }
        }
        else {
          if (entryData.input_type === 'check' && !data.required) { //チェックボックスであるが必須ではない時
            check = false; 
          }
          if (entryData.input_type === 'check' && data.required && state[entryData.param_name]) { //チェックボックスであり必須で値がある時
            check = false;
          }
        }
      });
      if (check === true) { //子のテーブルの中でcheckの値がfalseにならなければret1をtrueにする
        ret1 = true;
      }
    });
    if (ret === true || ret1 === true) { //retかret1がtrueであればtrueを返す
      return true;
    }
    else {
      return false;
    }
  }

  const submitClear = () => {
    const temp = state;
    state.entry_type.map((data, idx) => (
      data.m_entry_data.map((entryData, entryDataIdx) => (
        temp[entryData.param_name] = entryData.default_value
      ))
    ));
    setState(temp);
  }

  const submit = async () => {
    setDisabled(true);
    setConfirm(false);
    var data = {
      shop_id: props.match.params.shopId,
      line_id: constClass.PAPER_ORDER,
    };
    var entry_data = {};
    state.entry_type.filter(data => data.m_entry_data.length === 0 || data.m_entry_data[0].input_type !== "label").map((data, idx) => (
      data.m_entry_data.map((entryData, entryDataIdx) => (
        entry_data[entryData.param_name] = state[entryData.param_name]
      ))
    ));
    data['entry_data'] = entry_data;
    try {
      const my_order = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/order/`, data)).data;
      //setMessage(`受付番号を発行しました`);
      addToast(`整理券を発行しました`, { appearance: 'success', autoDismiss: true })
      console.log(my_order);
      // 整理券発行処理
      setOrder(my_order.order);
      printTicket();
      setDisabled(false);
    } catch (err) {
      // setMessage(err);
      console.log(err);
      //addToast(`エラーが発生しました。${err.response.status}:${err.response.data}`, { appearance: 'error', autoDismiss: true });
      addToast(`エラーが発生しました。${err.response}`, { appearance: 'error', autoDismiss: true });
    } finally {
      setDisabled(false);
      await submitClear();
    }
  }

  const getEntryType = useCallback(async () => {
    const reg_params = {
      "operator": "and",
      "where": [
        { "shop_id": props.match.params.shopId },
      ]
    }
    const entry_type = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/entry_type/search/`, reg_params)).data;
    entry_type.sort((a, b) => a.entry_type < b.entry_type ? -1 : 1);
    entry_type.map((d, idx) => (
      d.m_entry_data.sort((a, b) => a.entry_seq - b.entry_seq)
    ));
    var temp = {};
    temp['entry_type'] = entry_type;
    entry_type.map((data, idx) => (
      data.m_entry_data.map((entryData, entryDataIdx) => (
        temp[entryData.param_name] = entryData.default_value
      ))
    ));
    setState(temp);
  },[props.match.params.shopId]);

  useEffect(() => {
    getEntryType();
  }, [getEntryType]);

  return (
    <div className="container bg-white mx-3 px-0">      
      <div className="d-none">
        <Ticket order={order} path={`${process.env.REACT_APP_FRONTEND_URL}/${props.match.params.shopId}/Display/Signage`} entry_type={state.entry_type} {...props} ref={componentRef} />
      </div>
      <div className="row my-0 mx-0 px-0">
          <div className="col-12 px-0">
            <div>
            {state && state.entry_type && state.entry_type.map((data, idx) => (
              <div key={idx}>
                <div className="row mx-0">
                  <div className="col section-dark px-0">
                    <h3 className="mb-0 align-middle">
                      <span className="font-weight-bold">
                      {//丸数字１～50、51以降は51.と表記
                        idx < 20 ? String.fromCodePoint(9312 + idx) :
                        idx < 35 ? String.fromCodePoint(12881 - 20 + idx) :
                        idx < 50 ? String.fromCodePoint(12977 - 35 + idx) :
                        1 + idx + "."
                      }
                      </span>
                      {" " + data.entry_type_name}
                    </h3>
                  </div>
                </div>
                <div className={`row mx-0 bg-white px-3-env pt-3 pb-4 ${data.m_entry_data.length === 0 || data.m_entry_data[0].input_type !== "toggle" ? "justify-content-center":""}`}>
                {data.remarks && <label className={`w-100 ml-1 h4`}>{data.remarks}</label>}
                  {data.m_entry_data !== null && data.m_entry_data.map((entryData, entryDataIdx) => (
                    <Control target={"tb"} setState={(name, value) => { setState({ ...state, [name]: value }); }} m_entry_data={data.m_entry_data} entryData={entryData} entryDataIdx={entryDataIdx} value={state[entryData.param_name]} {...props} key={entryDataIdx} />
                  ))}
                </div>
              </div>
            ))}
              {/*クリア・発券*/}
              {/*<div className="row mx-0 bg-white px-3 pt-3 pb-4">*/}
                <div className="row mb-2 mx-0">
                  <div className="col-auto text-left mx-1 pr-0">
                    <button
                      disabled={(disabled)}
                      className="btn-lg btn-submit-cancel w-100 rounded"
                      onClick={() => { submitClear() }}>
                      <h1 className="mb-0">クリア</h1>
                    </button>
                  </div>
                  <div className="col text-right mx-1 pl-0">
                    <button
                      disabled={(checkDataInvalid() || disabled)}
                      className="btn-lg btn-blue-submit w-100 rounded"
                      onClick={() => { setConfirm(true) }}>
                      <h1 className="mb-0 align-middle d-inline-block mx-2">発券 </h1>
                      <img className="arrow-lg align-middle d-inline-block" src={checkDataInvalid() || disabled ? ArrowOff : ArrowOn} alt="" />
                    </button>
                  </div>
                </div>
              {/*</div>*/}
              {/* {message !== null && <p className="text-danger">{message}</p>} */}
            </div>
          </div>
      </div>
      
      <Modal isOpen={confirm} style={modalConfirmStyle}>
        <div className="">
          <table className="table-bordered table-striped w-auto m-3">
            <tbody>
            {/* m_entry_dataが存在しない場合またはinput_typeがlabelでない場合表示する */}
            {state && state.entry_type && state.entry_type.filter(data => data.m_entry_data.length === 0 || data.m_entry_data[0].input_type !== "label").map((data, idx) => (
              <tr className="mt-3 mx-0 font-h3" key={idx}>
                <th className="p-1 pl-2 pr-2 text-right">
                {data.entry_type_name}
                </th>
                <td className="p-1 pl-2 pr-2">
                {data.m_entry_data !== null && data.m_entry_data.map((entryData, entryDataIdx) => {
                  if (entryData.entry_data !== null) {
                    if (state[entryData.param_name] === entryData.entry_data) {
                      return entryData.entry_name;
                    } else {
                      return '';
                    }
                  } else {
                    if(state[entryData.param_name] === null){
                      return "";
                    }else{
                      return state[entryData.param_name] + ' ';
                    }
                  }
                })}
                </td>
              </tr>           
            ))}
            </tbody>
          </table>
          <footer className="bg-light">
            <div className="row mt-3 mx-0">
              <div className="col text-center">
                <h3 className="mb-0">{`上記の内容で整理券を発行します`}</h3>
              </div>
            </div>
            <div className="row my-3 mx-0">
              <div className="col">
                <button
                  disabled={disabled}
                  className={`btn btn-blue-enable w-100 py-2`}
                  onClick={() => { setConfirm(false) }}>
                  <h2 className="font-weight-bold mb-0">キャンセル</h2>
                </button>
              </div>
              <div className="col">
                <button
                  disabled={disabled}
                  className={`btn btn-blue-enable w-100 py-2`}
                  onClick={() => { submit() }}>
                  <h2 className="font-weight-bold mb-0">発行</h2>
                </button>
              </div>
            </div>
          </footer>
        </div>
      </Modal>
    </div>
  )
}

export default DisplayOrder;